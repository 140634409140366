html {
  border-collapse: collapse;
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1;
}
* {
  border-collapse: inherit;
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
*:not(body) {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
body {
  position: relative;
}
[hidden] {
  display: none !important;
}
ul,
ol,
menu {
  list-style: none;
}
a {
  color: inherit;
}
fieldset {
  border: none;
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/D-DIN-Italic.otf");
}
html {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
}
html main {
  flex-direction: column;
  min-height: 100vh;
}
.Toast {
  background-color: #c68007;
  color: #fff;
  padding: 10px;
  position: fixed;
  right: 50px;
  top: 50px !important;
  z-index: 999999;
}
@media (min-width: 768px) {
  .Toast {
    top: 200px !important;
  }
}
.Toast--failure {
  background-color: #d21212;
  color: #fff;
  padding: 10px;
  position: fixed;
  right: 50px;
  top: 50px !important;
  z-index: 999999;
}
@media (min-width: 768px) {
  .Toast--failure {
    top: 200px !important;
  }
}
.Toast--success {
  background-color: #20b461;
  color: #fff;
  padding: 10px;
  position: fixed;
  right: 50px;
  top: 50px !important;
  z-index: 999999;
}
@media (min-width: 768px) {
  .Toast--success {
    top: 200px !important;
  }
}
.custom_fc_frame {
  display: block;
  left: 5px !important;
  position: fixed !important;
  right: unset !important;
  z-index: 0 !important;
}
.custom_fc_frame--report {
  z-index: 1 !important;
}
.custom_fc_open {
  z-index: 3 !important;
}
.FilterContainer {
  position: absolute;
  top: 0;
  background-color: #fdfdfd;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  margin-top: 55px;
  padding: 0 25px 20px 25px;
  width: 290px;
  padding-top: 24px;
}
.FilterContainer--hidden {
  display: none;
}
.FilterContainer--up {
  bottom: 50px;
  left: 15px;
  position: absolute;
  background-color: #fdfdfd;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  margin-top: 55px;
  padding: 0 25px 20px 25px;
  width: 290px;
  padding-top: 24px;
}
