@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.NavMenuMobile {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  height: 60px;
  text-transform: uppercase;
}
.NavMenuMobile__MenuContainer {
  padding: 18px;
}
.NavMenuMobile__MenuContainer__Close {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 18px;
}
.NavMenuMobile__SidebarHeader {
  background-color: #29313e;
  height: 80px;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.3s ease;
  z-index: 10;
}
.NavMenuMobile__SidebarHeader__Open {
  width: 100%;
}
.NavMenuMobile__SidebarHeader__Close {
  width: 0;
}
.NavMenuMobile__Sidebar {
  background-color: #29313e;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 80px;
  transition: 0.3s ease;
  z-index: 10;
}
.NavMenuMobile__Sidebar__Open {
  width: 100%;
}
.NavMenuMobile__Sidebar__Close {
  width: 0;
}
.NavMenuMobile__Sidebar__Link {
  padding: 34px 46px 25px 22px;
}
.NavMenuMobile__Sidebar__Link a {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  text-align: left;
  text-decoration: none;
  text-transform: capitalize;
}
.NavMenuMobile__Sidebar__FixedHeader {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}
.NavMenuMobile__Logo {
  padding-left: 13px;
}
.NavMenuMobile__SocialMediaText {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #797e85;
  font-size: 16px;
  padding: 0 23px 20px 22px;
  text-align: left;
  text-transform: none;
}
.NavMenuMobile__SocialMediaLink {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #797e85;
  font-size: 16px;
  padding: 0 46px 20px 14px;
  text-align: left;
  text-decoration: none;
  text-transform: none;
}
.NavMenuMobile__SocialMediaContainer {
  margin-top: 97px;
}
