@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.Header {
  background-color: #29313e;
  color: #fff;
  height: 80px;
}
.Header--transparent {
  background-color: transparent;
  color: #fff;
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.Header--home {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.Header__Link {
  color: #fff;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 33px;
  margin-top: 33px;
  padding-right: 15px;
  text-decoration: none;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .Header__Hide {
    display: none;
  }
}
.Header__Pane {
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}
.Header__Modal {
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  padding: 16px;
  width: 80%;
}
.Header__ModalHeader {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #29313e;
}
.Header__FormRow {
  margin-bottom: 16px;
}
