@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.Footer {
  background-color: #29313e;
}
.Footer .container {
  border-bottom: 1px solid #94989e;
  padding-bottom: 16px;
}
@media (max-width: 768px) {
  .Footer {
    text-align: center;
  }
}
.Footer__FooterLink {
  color: #94989e;
  line-height: 22px;
  text-decoration: none;
}
.Footer__FooterLink:hover {
  text-decoration: underline;
}
.Footer__FooterText {
  color: #94989e;
  margin: 8px 0;
}
.Footer__FooterHeading {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: bold;
  color: #fff;
  margin: 39px 0 16px 0;
}
