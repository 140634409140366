@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
.Checkbox {
  cursor: pointer;
}
.Checkbox__Label {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #29313e;
  font-size: 16px;
  margin-left: 16px;
}
.Checkbox__Label--reverse {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #fff;
  font-size: 16px;
  margin-left: 16px;
}
.Checkbox__Input.rc-checkbox-checked .rc-checkbox-inner {
  background-color: #29313e;
  border-color: #29313e;
}
.Checkbox__Input:hover .rc-checkbox-inner,
.Checkbox__Input .rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: #29313e;
}
.Checkbox__Input .rc-checkbox-inner {
  border-color: #29313e;
  border-radius: 2px;
  height: 16px;
  width: 16px;
}
.Checkbox__Input--reverse.rc-checkbox-checked .rc-checkbox-inner {
  background-color: #fff;
  border-color: #29313e;
}
.Checkbox__Input--reverse:hover .rc-checkbox-inner,
.Checkbox__Input--reverse .rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: #29313e;
}
.Checkbox__Input--reverse .rc-checkbox-inner {
  border-radius: 2px;
  height: 16px;
  width: 16px;
}
.Checkbox__Input--reverse.rc-checkbox-checked .rc-checkbox-inner:after {
  border-bottom-color: #29313e !important;
  border-right-color: #29313e !important;
}
.Checkbox__LabelMobile {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #29313e;
  font-size: 18px;
  margin-left: 16px;
}
.Checkbox__InputMobile.rc-checkbox-checked .rc-checkbox-inner {
  background-color: #29313e;
  border-color: #29313e;
}
.Checkbox__InputMobile:hover .rc-checkbox-inner,
.Checkbox__InputMobile .rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: #29313e;
}
.Checkbox__InputMobile .rc-checkbox-inner {
  border-radius: 2px;
  height: 24px;
  width: 24px;
}
.Checkbox__InputMobile.rc-checkbox-checked .rc-checkbox-inner:after {
  height: 12px;
  left: 9px !important;
  top: 3px !important;
}
.Checkbox__LabelMobileSub {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #29313e;
  font-size: 16px;
  margin-left: 16px;
}
.Checkbox__InputMobileSub.rc-checkbox-checked .rc-checkbox-inner {
  background-color: #29313e;
  border-color: #29313e;
}
.Checkbox__InputMobileSub:hover .rc-checkbox-inner,
.Checkbox__InputMobileSub .rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: #29313e;
}
.Checkbox__InputMobileSub .rc-checkbox-inner {
  border-radius: 2px;
  height: 20px;
  width: 20px;
}
.Checkbox__InputMobileSub.rc-checkbox-checked .rc-checkbox-inner:after {
  height: 9px !important;
  left: 7px !important;
  top: 3px !important;
}
