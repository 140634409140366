@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
.SearchMap {
  font-size: 12px;
  height: calc(100vh - 145px);
  transition: 0.3s ease;
  width: 100%;
  z-index: 1;
}
.SearchMap div div div .gm-svpc {
  border-radius: 5px !important;
  bottom: -113px !important;
  left: unset !important;
  position: relative !important;
  right: 55px !important;
  top: unset !important;
}
.SearchMap .gm-ui-hover-effect {
  display: none !important;
}
.SearchMap div div div div .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom .gmnoprint div {
  border-radius: 5px !important;
}
.SearchMap div div div .gm-control-active.gm-fullscreen-control {
  display: none !important;
}
.VisualizeButton {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  background-color: #f8f8f9;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  font-size: 16px;
  height: 40px;
  line-height: 16px;
  width: 137px;
}
.VisualizeButton__Badge {
  bottom: unset !important;
  margin: 5px 0 5px 5px;
  right: unset !important;
}
.VisualizeButton__Popover {
  background-color: #fdfdfd;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  margin-top: 55px;
  padding: 0 25px 20px 25px;
  width: 290px;
  max-height: 238px;
  padding: 15px;
  width: 229px;
}
.VisualizeButton__Icon {
  display: inline-block;
  font-size: 16px;
  margin-right: 8px;
  pointer-events: none;
  vertical-align: text-bottom;
}
.DrawButton {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  background-color: #f8f8f9;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  font-size: 16px;
  height: 40px;
  line-height: 16px;
  width: 197px;
}
.DrawButton__Icon {
  display: inline-block;
  font-size: 16px;
  margin-right: 8px;
  pointer-events: none;
  vertical-align: sub;
}
.DrawButton__Instruction {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #94989e;
  font-size: 10px;
  font-stretch: normal;
  padding: 0 12px;
}
