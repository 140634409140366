@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
[class*='btn-link---'] {
  color: #29313e;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}
[class*='btn-border---'] {
  background-image: none;
  border-color: #b28b3d;
  color: #b28b3d;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
}
[class*='btn-primary---'] {
  background-color: #b28b3d;
  background-image: none;
  border-color: #b28b3d;
  color: #fff;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
}
[class*='btn-primary---']:hover,
[class*='btn-primary---']:active,
[class*='btn-primary---']:focus {
  background-color: #b18935;
  background-image: none;
  border-color: #b18935;
}
