@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.Alert__Header {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  margin-bottom: 8px;
}
.Alert__Info {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #bec1c6;
  font-size: 16px;
}
.Alert__Row {
  margin-bottom: 24px;
}
.Alert__Error {
  color: #d21212;
  margin: 5px;
}
.Alert--error {
  border: 1px solid #d21212 !important;
}
.Alert__Toast--success {
  background-color: #20b461;
  color: #fff;
  padding: 10px;
  position: fixed;
  right: 50px;
  top: 200px !important;
}
.Alert__Toast--failure {
  background-color: #d21212;
  color: #fff;
  padding: 10px;
  position: fixed;
  right: 50px;
  top: 200px !important;
}
