@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
.FilterPriceRange {
  position: relative;
}
.FilterPriceRange div div {
  z-index: 1;
}
.FilterPriceRange__Popover {
  background-color: #fdfdfd;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  margin-top: 55px;
  padding: 0 25px 20px 25px;
  width: 290px;
}
.FilterPriceRange__Popover__Info {
  color: #94989e;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 1.32;
  text-align: left;
}
.FilterPriceRange__Popover .rc-slider-handle {
  border-color: rgba(0,0,0,0.1);
  border-width: 0.5px;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.1);
  height: 28px;
  margin-left: -14px;
  margin-top: -10px;
  width: 28px;
}
.FilterPriceRange__Popover .rc-slider-track {
  background-color: #29313e;
}
.FilterPriceRange__Popover__Cancel {
  color: #797e85;
}
