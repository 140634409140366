@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
.SelectTypeahead {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  border: solid 1px #e1e2e0;
  border-radius: 3px;
  box-sizing: border-box;
  color: #94989e;
  display: flex;
  font-size: 13.3px;
  height: 48px;
  line-height: 24px;
  max-width: 100%;
  overflow: hidden;
  padding: 10px 15px;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
}
.SelectTypeahead:hover,
.SelectTypeahead:visited,
.SelectTypeahead:focus,
.SelectTypeahead--active,
.SelectTypeahead--hover,
.SelectTypeahead--focus {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  border: solid 1px #e1e2e0;
  border-radius: 3px;
  box-sizing: border-box;
  color: #94989e;
  display: flex;
  font-size: 13.3px;
  height: 48px;
  line-height: 24px;
  max-width: 100%;
  overflow: hidden;
  padding: 10px 15px;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  width: 100%;
}
.SelectTypeahead__ScrollContainer {
  max-height: 150px;
  overflow-y: auto;
}
.SelectTypeahead__OptionContainer {
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  max-height: 200px;
  max-width: calc(100% - 18px);
  padding-top: 10px;
  position: absolute;
  width: calc(100% - 18px);
  z-index: 3;
}
.SelectTypeahead__OptionContainer div div {
  cursor: pointer;
  padding: 8px;
}
.SelectTypeahead__OptionContainer div div:hover {
  background-color: #e1e2e0;
}
.SelectTypeahead__SearchInput {
  border: 0;
  border-bottom: 1px solid #e1e2e0;
  margin: 0 8px;
  outline: none;
  padding: 8px;
  width: calc(100% - 16px);
}
.SelectTypeahead__SearchInput:focus {
  border-bottom: 1px solid #b18935;
}
.SelectTypeahead__Label {
  color: #29313e;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
  color: #29313e;
  display: block;
  font-size: 16px;
  line-height: 30px;
  padding-bottom: 9px;
  text-align: left;
  width: 100%;
}
.SelectTypeahead__Input {
  color: #94989e;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  border: solid 1px #e1e2e0;
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  height: 48px;
  line-height: 24px;
  padding: 10px 15px;
  width: 100%;
}
.SelectTypeahead__Input--error {
  border-color: #d21212;
}
.SelectTypeahead__Feedback {
  color: #d21212;
  margin-top: 4px;
}
