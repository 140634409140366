@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
.FilterMore {
  position: relative;
}
.FilterMore div div {
  z-index: 1;
}
.FilterMore__Popover {
  background-color: #fdfdfd;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  margin-top: 55px;
  padding: 0 25px 20px 25px;
  width: 290px;
}
.FilterMore__Popover__Cancel {
  color: #797e85;
}
.FilterMore__MoreDetailsRow {
  padding: 8px !important;
}
.FilterMore__Search {
  padding-right: 8px !important;
}
.FilterMore__CustomPadColumn.col-xs-6 {
  padding: 0 4px 0 0;
}
.FilterMore__CustomPadColumn.col-xs-6:last-child {
  padding: 0 0 0 4px;
}
.FilterMore__SliderColumn {
  padding-left: 15px;
}
.FilterMore__NoPadColumn {
  padding-right: 0;
}
.FilterMore__ArrowIcon {
  float: right;
}
.FilterMore__MoreDetails {
  cursor: pointer;
}
.FilterMore__NoPadFieldColumn {
  padding-left: 0;
}
.FilterMore .rc-slider-handle {
  border-color: rgba(0,0,0,0.1);
  border-width: 0.5px;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.1);
  height: 28px;
  margin-left: -14px;
  margin-top: -10px;
  width: 28px;
}
.FilterMore .rc-slider-track {
  background-color: #29313e;
}
.FilterMore__OptionContainer {
  margin-top: 15px;
  max-height: 290px;
  overflow-x: hidden;
  overflow-y: auto;
}
.FilterMore__OptionContainer::-webkit-scrollbar {
  background-color: #e1e2e0;
  border-radius: 10px;
  width: 8px;
}
.FilterMore__OptionContainer::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 10px;
}
.FilterMore__OptionContainer__SubOption {
  margin-left: 5px;
}
.FilterMore__Instructions {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #94989e;
  font-size: 10px;
  font-stretch: normal;
  text-align: left;
}
.FilterMore__MacroOption {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  padding-left: 8px;
}
.FilterMore__ButtonContainer {
  display: flex;
}
.FilterMore__OptionButton {
  background-color: #fff;
  border: 1px solid #29313e;
  border-radius: 3px;
  color: #29313e;
  cursor: pointer;
  margin-right: 8px;
  padding: 8px;
  user-select: none;
  width: 34px;
}
.FilterMore__OptionButton--active {
  background-color: #29313e;
  color: #fff;
}
.FilterMore__MicroOption {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 0 8px;
}
.FilterMore__MicroOption__RoundButton {
  background-color: #fdfdfd;
  border: 1px solid #29313e;
  border-radius: 50%;
  cursor: pointer;
  height: 34px;
  padding: 7.5px 12px;
  width: 34px;
}
.FilterMore__MicroOption__Quantity {
  color: #94989e;
  padding-top: 10px;
  width: 37px;
}
.FilterMore__MicroOption__Label {
  padding: 0;
  padding-top: 10px;
  text-align: left;
}
