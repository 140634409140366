@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.OurTools {
  align-items: center;
  background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("https://images.pexels.com/photos/373912/pexels-photo-373912.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding-top: 80px;
}
@media (max-width: 768px) {
  .OurTools {
    height: 88vh;
    padding: 0 16px;
    text-align: center;
  }
}
.OurTools__Tip {
  background-color: #fff;
  display: flex;
  height: 25px;
  margin: 0 auto;
  position: absolute;
  top: calc(100vh - 25px);
  width: 90%;
}
@media (max-width: 768px) {
  .OurTools__Tip {
    padding: 0 16px;
    text-align: center;
    top: calc(88vh - 25px);
  }
}
.OurTools__Header {
  margin: 8px 0;
  text-align: center;
}
@media (min-width: 768px) {
  .OurTools__Header {
    font-size: 64px;
  }
}
.OurTools__HeaderDesktop {
  display: none;
}
@media (min-width: 768px) {
  .OurTools__HeaderDesktop {
    display: block;
  }
}
@media (min-width: 768px) {
  .OurTools__HeaderMobile {
    display: none;
  }
}
.OurTools__Paragraph {
  font-size: 18px;
  margin: 24px 0;
}
.OurTools__Container {
  height: 100%;
}
.OurTools__MiniHeader {
  margin: 8px 0;
}
.OurTools__Text {
  color: #797e85;
}
.OurTools__Descriptions {
  padding: 48px 0;
}
@media (max-width: 768px) {
  .OurTools__Descriptions {
    padding-bottom: 0;
  }
  .OurTools__Descriptions .col-xs-12 {
    margin-bottom: 16px;
  }
}
.OurTools__CommentContainer {
  display: flex;
  height: 184px;
  margin: 0 auto;
  padding: 32px 0;
  width: 60%;
}
@media (max-width: 768px) {
  .OurTools__CommentContainer {
    height: 240px;
    width: 90%;
  }
}
.OurTools__CommentArrow {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 10%;
}
.OurTools__CommentArrow i {
  cursor: pointer;
}
@media (max-width: 768px) {
  .OurTools__CommentArrow {
    width: 5%;
  }
}
.OurTools__Comment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 80%;
}
@media (max-width: 768px) {
  .OurTools__Comment {
    width: 90%;
  }
  .OurTools__Comment h2 {
    font-size: 20px;
  }
}
.OurTools__CommentText {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #797e85;
  text-align: center;
}
.OurTools__CommentBy {
  color: #94989e;
  padding-top: 8px;
}
.OurTools__TalkWithUs {
  align-items: center;
  background-color: #29313e;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.OurTools__TalkWithUs h2 {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
}
.OurTools__TalkWithUs .container {
  max-width: 418px;
  padding: 32px;
}
.OurTools__TalkWithUs button {
  margin-top: 8px;
}
.OurTools__VideoNews .row,
.OurTools__VideoNews iframe {
  margin-bottom: 8px;
}
.OurTools__VideoNews h1 {
  margin-top: 16px;
}
@media (max-width: 768px) {
  .OurTools__VideoNews {
    padding: 16px !important;
  }
  .OurTools__VideoNews iframe {
    width: 100%;
  }
}
.OurTools__OurChannel {
  color: #b18935;
  text-decoration: none;
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: bold;
}
.OurTools__OurChannel:hover {
  text-decoration: underline;
}
.OurTools__Img {
  width: 100%;
}
.OurTools__NewsText {
  color: #94989e;
}
.OurTools__NewsLink {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: bold;
  color: #29313e;
  text-decoration: none;
}
.OurTools__NewsLink:hover {
  text-decoration: underline;
}
.OurTools__BusinessMan {
  height: 100%;
}
@media (max-width: 768px) {
  .OurTools__BusinessMan {
    display: none;
  }
}
.OurTools__Grid {
  padding: 32px 0 0 0;
}
.OurTools__Help .row {
  margin-top: 16px;
}
.OurTools__Help p {
  color: #94989e;
}
@media (max-width: 768px) {
  .OurTools__Help {
    padding: 16px !important;
    text-align: center;
  }
  .OurTools__Help .col-xs-12 {
    margin-bottom: 16px;
  }
}
.OurTools__Footer {
  background-color: #29313e;
}
.OurTools__Footer .container {
  border-bottom: 1px solid #94989e;
  padding-bottom: 16px;
}
@media (max-width: 768px) {
  .OurTools__Footer {
    text-align: center;
  }
}
.OurTools__FooterLink {
  color: #94989e;
  line-height: 22px;
  text-decoration: none;
}
.OurTools__FooterLink:hover {
  text-decoration: underline;
}
.OurTools__FooterText {
  color: #94989e;
  margin: 8px 0;
}
.OurTools__FooterHeading {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: bold;
  color: #fff;
  margin: 39px 0 16px 0;
}
