@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.Search {
  overflow: hidden;
}
.Search .PageBase__Grid {
  padding: 0;
}
.Search__Toast {
  background-color: #c68007;
  color: #fff;
  padding: 10px;
  position: fixed;
  right: 50px;
  top: 200px !important;
}
.Search__Toast--failure {
  background-color: #d21212;
  color: #fff;
  padding: 10px;
  position: fixed;
  right: 50px;
  top: 200px !important;
}
.Search__Toast--warning {
  background-color: #b28b3d;
  color: #fff;
  padding: 10px;
  position: fixed;
  right: 50px;
  top: 200px !important;
}
.Search__MapButton {
  background-color: #f8f8f9;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  font-size: 16px;
  height: 40px;
  margin-left: 15px;
  width: auto;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
}
.Search__MapButton i {
  color: #29313e;
  display: inline-block;
  vertical-align: sub;
}
.Search__MapBtnContainer {
  bottom: 24px;
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  right: 120px;
  width: 100%;
}
.Search__MarkerContainer {
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translate(-50%, -100%);
}
.Search__Pane {
  align-items: center;
  background-color: rgba(0,0,0,0.4);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}
.Search__Pane i {
  cursor: pointer;
}
.Search__Pane--hidden {
  display: none;
}
.Search__Radius {
  background-color: #fff;
  border-radius: 5px;
  padding: 8px;
  position: absolute;
}
.Search__RadiusContainer {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .Search__RadiusContainer {
    align-items: center;
    flex-direction: row;
  }
  .Search__RadiusContainer span {
    flex: 1;
    margin-right: 8px;
  }
  .Search__RadiusContainer input {
    align-self: flex-end;
    border: 1px solid #bec1c6;
    height: 30px;
    margin-right: 8px;
    padding: 0 8px;
  }
}
.Search__AlertContainer {
  background-color: #f8f8f9;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  padding: 15px 0;
  position: fixed;
  right: 0;
  top: 189px;
  transition: width 0.3s ease;
  z-index: 2;
}
.Search__AlertContainer--open {
  width: 50vw;
}
.Search__AlertContainer--close {
  width: 0;
}
.Search__AlertContainer__AlertClose {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
}
.Search__ButtonContainer {
  position: relative;
}
.Search__ButtonContainer div div {
  bottom: 50px;
  left: unset !important;
  right: 10px;
  top: unset !important;
}
.Search__Bars {
  border-bottom: 1px solid #e1e2e0;
  display: flex;
  flex-direction: column;
}
.Search .SearchBar {
  z-index: 2;
}
.Search .FilterBar {
  z-index: 1;
}
.Search__ShowRealtyBtn {
  animation: bounce 0.5s;
  background-color: #f8f8f9;
  border: 0;
  border-radius: 5px;
  bottom: 24px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  font-size: 16px;
  height: 40px;
  line-height: 16px;
  position: absolute;
  right: 16px;
  top: 150px;
}
.Search__ShowRealtyBtn i {
  color: #29313e;
  display: inline-block;
  margin-right: 8px;
  vertical-align: sub;
}
.Search__ShowRealtyBar {
  animation: slide 0.2s;
  background-color: rgba(255,255,255,0.8);
  height: 45px;
  position: absolute;
  top: 187px;
  width: 100%;
  z-index: 1;
}
@media (min-width: 993px) {
  .Search__ShowRealtyBar {
    top: 145px;
  }
}
.Search__ShowRealtyBar__Container {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 45px;
  justify-content: center;
}
.Search__ShowRealtyBar__Count {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: bold;
  padding: 0 16px;
}
.Search__ShowRealtyBar__Count__Text {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #b18935;
  cursor: pointer;
  text-decoration: underline;
}
.Search__ShowRealtyBar__ShowRealty {
  padding: 0 16px;
}
.Search__ShowRealtyBar__Total {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: bold;
  padding-right: 16px;
}
.Search__ShowRealtyBar__Total__Text {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #b18935;
}
.Search__ShowRealtyBar__ShowRealtyBtn {
  animation: bounce 0.5s;
  background-color: #f8f8f9;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  font-size: 16px;
  height: 40px;
  line-height: 16px;
}
.Search__ShowRealtyBar__ShowRealtyBtn i {
  color: #29313e;
  display: inline-block;
  margin-right: 8px;
  vertical-align: sub;
}
.Search__Animated {
  animation: shake 1s infinite;
}
.Search__GlassPane {
  background-color: rgba(255,255,255,0.7);
  height: 144px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;
}
.Search__ContentContainer {
  display: flex;
  max-height: calc(100vh - 145px);
}
.Search__MapContainer {
  position: relative;
  transition: 0.3s ease;
  width: 100%;
}
.Search__MapContainer--active {
  position: relative;
  transition: 0.3s ease;
  width: 50%;
}
.Search__SideContainer {
  transition: 0.3s ease;
  width: 0;
}
.Search__SideContainer--active {
  max-height: calc(100vh - 144px);
  min-width: 50%;
  overflow: auto;
  padding: 16px;
  transition: 0.3s ease;
  width: 50%;
}
@-moz-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@-webkit-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@-o-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@-moz-keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-o-keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-moz-keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@-o-keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
