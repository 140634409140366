@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.UserMenu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: 16px;
  margin-top: 33px;
  position: relative;
}
.UserMenu__Container {
  display: flex;
  justify-content: flex-end;
  max-width: 84.5px;
  min-width: 84.5px;
  overflow: visible;
}
.UserMenu__ItemsContainer {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 225px;
  padding-bottom: 15px;
  padding-top: 23px;
  position: absolute;
  top: 66px;
  width: 193px;
  z-index: 3;
}
.UserMenu__ItemsContainer::after {
  border: 13px solid transparent;
  border-bottom-color: #fff;
  border-top: 0;
  content: '';
  height: 0;
  margin-left: -6px;
  margin-top: -25px;
  position: absolute;
  right: 18px;
  top: 19px;
  width: 0;
}
.UserMenu__UserBtn {
  color: #e1e2e0;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  width: 65px;
}
.UserMenu__Item {
  color: #29313e;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: normal;
  line-height: 30px;
  margin-left: 25px;
  text-align: left;
  text-decoration: none;
  width: 127px;
}
.UserMenu__Item__Exit {
  color: #797e85;
}
.UserMenu__TargetContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.UserMenu__Arrow {
  color: #e1e2e0;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  pointer-events: none;
  vertical-align: text-top;
}
.UserMenu__Notification {
  cursor: pointer;
  font-size: 16px;
  margin-left: 8px;
}
