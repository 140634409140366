@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.ShowRealty {
  align-items: center;
  background-color: rgba(0,0,0,0.2);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 21;
}
.ShowRealty__Loader {
  align-items: center;
  display: flex;
  justify-content: center;
}
.ShowRealty--hidden {
  display: none;
}
.ShowRealty__Modal {
  background-color: #fff;
  border-radius: 5px;
  height: 95%;
  padding: 15px;
  width: 95%;
}
.ShowRealty__NotModal {
  height: 590px;
  padding: 0 16px;
}
@media (max-width: 768px) {
  .ShowRealty__NotModal {
    height: 500px;
    padding: 0;
  }
}
.ShowRealty__Evaluation {
  max-width: 98vw;
  width: 100%;
}
.ShowRealty__Evaluation--nomargin {
  width: 100%;
}
@media (max-width: 768px) {
  .ShowRealty__Evaluation {
    margin: 0 16px;
    width: calc(100% - 32px);
  }
}
.ShowRealty__Header {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}
.ShowRealty__Header i {
  cursor: pointer;
}
.ShowRealty__Toolbar {
  align-self: start;
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (max-width: 768px) {
  .ShowRealty__Toolbar div,
  .ShowRealty__Toolbar input {
    margin-bottom: 5px;
    margin-left: 0 !important;
  }
  .ShowRealty__Toolbar button {
    width: 50%;
  }
  .ShowRealty__Toolbar div {
    display: flex;
  }
}
@media (min-width: 768px) {
  .ShowRealty__Toolbar {
    flex-direction: row;
  }
}
.ShowRealty__Footer {
  align-items: center;
  display: flex;
  justify-content: center;
}
.ShowRealty__Footer i {
  cursor: pointer;
  font-size: 42px;
}
.ShowRealty__Footer .cursor-wait {
  cursor: wait;
}
.ShowRealty__ScrollContainer {
  max-height: 100%;
  min-height: 50%;
  overflow-x: hidden;
  overflow-y: auto;
}
.ShowRealty__ScrollContainer::-webkit-scrollbar {
  background-color: #e1e2e0;
  border-radius: 10px;
  width: 8px;
}
.ShowRealty__ScrollContainer::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 10px;
}
.ShowRealty__Body {
  height: calc(95% - 121px);
}
@media (min-width: 768px) {
  .ShowRealty__Body {
    height: calc(95% - 63px);
  }
}
.ShowRealty__Table {
  height: 100%;
}
.ShowRealty__Actions {
  padding: 5px;
  width: 100%;
}
.ShowRealty__Row {
  align-items: center;
}
.ShowRealty__Link {
  background-color: #b18935;
  background-image: none;
  border-color: #b18935;
  color: #fff;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  font-size: 16px;
  min-width: 36px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.ShowRealty--sortable {
  cursor: pointer;
}
.ShowRealty--sortable i {
  display: inline-block;
  vertical-align: sub;
}
.ShowRealty--loading {
  cursor: progress;
}
.ShowRealty__Selection {
  cursor: pointer;
}
.ShowRealty__Selection i {
  cursor: pointer;
}
.ShowRealty__SearchInput {
  border: 0;
  border-bottom: 1px solid #797e85;
  height: 39px;
  margin-right: 8px;
  outline: none;
  padding: 5px;
  width: 100%;
}
@media (min-width: 768px) {
  .ShowRealty__SearchInput {
    width: calc(100% - 284px);
  }
}
.ShowRealty__SearchInput--small {
  width: 30%;
}
.material-icons {
  display: inline-block;
  vertical-align: sub;
}
