@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.Marker:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.Marker__Eepin {
  background-image: url("../../assets/images/eepin.png");
  height: 50px;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translate(-50%, -100%);
  width: 50px;
}
.Marker__Eepin:hover {
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translate(-50%, -100%) scale(1.05);
}
.Marker__InfoWindow {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  bottom: 45px;
  height: 150px;
  max-width: 400px;
  padding: 15px;
  position: absolute;
}
.Marker__InfoWindow__Modal {
  height: 100%;
}
.Marker__InfoWindow__Modal__Header {
  display: flex;
  height: 15px;
  justify-content: flex-end;
}
.Marker__InfoWindow__Modal__Header i {
  cursor: pointer;
  font-size: 15px;
  margin-right: -3px;
}
.Marker__InfoWindow__Modal__Body {
  height: calc(100% - 15px);
}
.Marker__InfoWindow__Modal__Body__Content {
  font-size: 14px;
  white-space: pre;
}
.Marker__InfoWindow__Modal__Body__ScrollContainer {
  max-height: 100%;
  max-width: calc(100% -15px);
  overflow-x: hidden;
  overflow-y: auto;
}
.Marker__InfoWindow__Modal__Body__ScrollContainer::-webkit-scrollbar {
  background-color: #e1e2e0;
  border-radius: 10px;
  width: 8px;
}
.Marker__InfoWindow__Modal__Body__ScrollContainer::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 10px;
}
