@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.InfoWindow {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  height: 270px;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 100%;
  transform: translate(-50%, -100%) translateY(-60px);
  white-space: pre;
  width: 40vw;
}
.InfoWindow--mobile {
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
}
.InfoWindow__Row {
  font-size: 16px;
}
.InfoWindow__Row .col-xs-12 {
  padding: 5px 0;
}
@media (min-width: 768px) {
  .InfoWindow__Row {
    font-size: 13px;
    font-weight: normal;
    height: 13px;
    line-height: 13px;
    padding: 12px;
  }
}
.InfoWindow--capitalized {
  text-transform: capitalize;
}
.InfoWindow--overflowhidden {
  overflow: hidden;
  text-overflow: ellipsis;
}
.InfoWindow__Header {
  display: flex;
  height: 24px;
  justify-content: flex-end;
  padding: 8px;
}
.InfoWindow__Header i {
  cursor: pointer;
  font-size: 24px;
}
@media (min-width: 768px) {
  .InfoWindow__Header {
    display: flex;
    height: 15px;
    justify-content: flex-end;
    padding: 0;
  }
  .InfoWindow__Header i {
    cursor: pointer;
    font-size: 15px;
  }
}
.InfoWindow__Loader {
  align-items: center;
  display: flex;
  height: calc(100% - 15px);
  justify-content: center;
}
.InfoWindow__Body {
  height: 100%;
  overflow: hidden;
}
.InfoWindow__Body__Content {
  font-size: 12px;
  height: calc(100% - 15px);
}
.InfoWindow__Body__Content__Title {
  margin: 5px 0;
}
.InfoWindow__Body__Content__Btn {
  font-size: 16px;
  height: 40px;
  line-height: 16px;
}
.InfoWindow__Body__Content__Btn i {
  color: #29313e;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}
.InfoWindow:after {
  border: 18px solid transparent;
  border-bottom: 0;
  border-top-color: #fff;
  bottom: 0;
  content: '';
  height: 0;
  left: 50%;
  margin-bottom: -18px;
  margin-left: -18px;
  position: absolute;
  width: 0;
}
