@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
.Switch {
  float: right;
}
.Switch.rc-switch-checked {
  background-color: #29313e;
  border: 1px solid #29313e;
}
.Switch.rc-switch-checked .rc-switch-inner .material-icons {
  color: $gray28;
  font-size: 16px;
  margin-top: 3px;
}
.Switch__Label {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
}
