@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.Report {
  padding: 15px 0;
}
.Report__Row {
  padding: 15px 0;
}
.Report__Row .Report__Content__Maps__StreetView,
.Report__Row .Report__Content__Maps__Static {
  height: 330px;
  max-height: 330px;
}
.Report__Row__Content {
  padding: 15px 8px;
}
.Report__InnerCol {
  padding: 2px 0;
}
.Report__GoldLine {
  background-color: #b18935;
  height: 1px;
  text-align: left;
  width: 40%;
}
.Report__Download {
  border-radius: 50%;
  bottom: 16px;
  height: 40px;
  position: fixed;
  right: 16px;
  width: 40px;
  z-index: 1;
}
.Report__Download i {
  color: #fff;
  display: block;
  vertical-align: sub;
}
@media (min-width: 768px) {
  .Report__Download {
    border-radius: 50%;
    bottom: 40px;
    height: 40px;
    position: fixed;
    right: 80px;
    width: 40px;
  }
  .Report__Download i {
    color: #fff;
    display: block;
    vertical-align: sub;
  }
}
.Report__Header {
  flex-direction: column-reverse;
}
@media (min-width: 768px) {
  .Report__Header {
    flex-direction: row;
  }
}
.Report__Header__Title {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: bold;
  color: #29313e;
  font-size: 42px;
}
.Report__Header__Logo {
  height: 100%;
  width: 100%;
}
.Report__Header__Logo__Image {
  height: 80px;
}
.Report__Content--bold {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: bold;
}
.Report__Content__Maps__Static {
  height: 330px;
  width: 100%;
}
.Report__Content__Maps__Static img {
  height: 100%;
  width: 100%;
}
.Report__Content__Maps__StreetView {
  height: 330px;
}
.Report__Content__Features {
  height: 188px;
}
.Report__Content__Features__Title {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: bold;
  color: #29313e;
  font-size: 28px;
}
