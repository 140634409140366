@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
.FilterBusinessType {
  position: relative;
}
.FilterBusinessType div div {
  z-index: 1;
}
.FilterBusinessType__Private {
  margin-top: 15px;
  padding-top: 0 !important;
}
.FilterBusinessType__HorizontalRule {
  background-color: rgba(0,0,0,0.1);
  border: none;
  color: rgba(0,0,0,0.1);
  height: 1px;
  margin: 16px -8px 0 -8px;
}
.FilterBusinessType__Popover {
  background-color: #fdfdfd;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  margin-top: 55px;
  padding: 0 25px 20px 25px;
  width: 290px;
}
.FilterBusinessType__Popover__Cancel {
  color: #797e85;
}
