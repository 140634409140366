@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
.SearchBar {
  background-color: #fff;
  border-bottom: 1px solid #e1e2e0;
  box-shadow: 0 2px 20px rgba(0,0,0,0.1);
  height: 64px;
}
.SearchBar__ColumnButtons {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: flex-end;
}
.SearchBar__ColumnButtons [class*='buttons---btn---'] {
  margin-left: 15px;
  padding-left: 36px;
  padding-right: 36px;
}
.SearchBar__ColumnButtons [class*='buttons---btn---']:hover,
.SearchBar__ColumnButtons [class*='buttons---btn---']:active {
  background-color: #f8f4eb;
  border-color: #a07d37;
  color: #a07d37;
}
.SearchBar__ColumnButtons [class*='btn-primary---']:hover,
.SearchBar__ColumnButtons [class*='btn-primary---']:active {
  background-color: #c19949;
  border-color: #a07d37;
  color: #f8f4eb;
}
.SearchBar__ColumnInput {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: flex-start;
  position: relative;
}
.SearchBar__ColumnInput__Icon {
  height: 30px;
  width: 30px;
}
.SearchBar__ColumnInput__Input {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  border: 0;
  border-radius: 0;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  outline: 0;
  width: 357px;
  -webkit-appearance: unset;
}
.SearchBar__OptionContainer {
  position: absolute;
  text-align: left;
  width: calc(100% - 54px);
  z-index: 12;
}
