@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.Statistics {
  margin-top: 34px;
  transition: 0.3s ease;
  width: 0;
}
.Statistics i {
  cursor: pointer;
}
.Statistics--active {
  transition: 0.3s ease;
  width: 100%;
}
.Statistics__Header {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
}
.Statistics__Pane {
  align-items: center;
  background-color: rgba(0,0,0,0.4);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
}
.Statistics__Pane--hidden {
  display: none;
}
.Statistics__Modal {
  background-color: #fff;
  border-radius: 5px;
  height: 95%;
  padding: 15px;
  width: 95%;
}
.Statistics__Modal__Table {
  height: calc(100% - 55px);
}
.Statistics__Modal__Body {
  max-height: 100%;
  overflow-y: auto;
}
