@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.Login {
  align-items: center;
  background-color: #f8f8f9;
  background-color: #29313e;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}
.Login__Container {
  align-items: center;
  animation: bounce 0.5s;
  background-color: #f8f8f9;
  border: 1px solid #bec1c6;
  border-radius: 5px;
  box-shadow: 4px 4px 8px rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  padding: 15px;
  width: 300px;
}
.Login__Visibility {
  background-color: transparent;
  border: 0;
  color: #94989e;
  cursor: pointer;
  outline: 0;
  position: relative;
  right: -236px;
  top: -35px;
  width: 24px;
}
.Login__Visibility i {
  width: 24px;
}
.Login__PasswordRow {
  height: 48px;
}
.Login__ErrorCol {
  margin: 5px 0;
}
.Login__ForgotPasswordCol {
  margin: 5px 0;
  text-align: center;
}
.Login__Error {
  color: #d21212;
  font-size: 14px;
  line-height: 14px;
}
.Login__Input {
  border: solid 1px #e1e2e0;
  border-radius: 3px;
  box-sizing: border-box;
  color: #94989e;
  display: block;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  height: 48px;
  line-height: 24px;
  padding: 10px 15px;
  width: 100%;
}
.Login__Form {
  width: 100%;
}
.Login__Pane {
  background-color: rgba(0,0,0,0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.Login__Pane--hidden {
  display: none;
}
.Login__Modal {
  background-color: #fff;
  margin: 0 auto;
  max-width: 100%;
  padding: 16px;
  position: absolute;
  top: 50px;
  width: fit-content;
}
.Login__Modal h2,
.Login__Modal h4 {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #29313e;
}
.Login__Modal i {
  cursor: pointer;
}
.Login__Modal button {
  margin-top: 16px;
}
.Login__LoadingContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}
.Login__Spinner {
  height: 16px;
  margin-right: 5px;
}
.Login__LogoContainer {
  height: 60px;
  width: 60px;
}
@-moz-keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@-o-keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
