@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
.FilterBar__Container {
  padding: 0;
}
.FilterBar__Container__Count {
  color: #bec1c6;
  margin: 0 10px;
  text-decoration: underline;
}
.FilterBar__Container__Filters {
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 2;
}
.FilterBar__Container__Filters .SearchFilter .row {
  padding-top: 24px;
}
.FilterBar__Container__Filters .SearchFilter [class*='__Popover'] .row:last-child [class*='buttons---btn---'] {
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
}
.FilterBar__Container__Filters .SearchFilter [class*='__Popover'] .row:last-child [class*='buttons---btn---']:hover,
.FilterBar__Container__Filters .SearchFilter [class*='__Popover'] .row:last-child [class*='buttons---btn---']:visited,
.FilterBar__Container__Filters .SearchFilter [class*='__Popover'] .row:last-child [class*='buttons---btn---']:focus {
  color: #29313e;
  text-decoration: none;
}
.FilterBar__Container__Filters .SearchFilter.FilterCategory div {
  z-index: 2;
}
.FilterBar__Container__Filters .FilterButton {
  margin-right: 5px;
}
.FilterBar__Container__Filters .FilterButton__Btn {
  line-height: 30px;
  text-decoration: none;
}
.FilterBar__Container__Filters .FilterButton__Btn:hover,
.FilterBar__Container__Filters .FilterButton__Btn:visited,
.FilterBar__Container__Filters .FilterButton__Btn:focus,
.FilterBar__Container__Filters .FilterButton__Btn--active,
.FilterBar__Container__Filters .FilterButton__Btn--hover,
.FilterBar__Container__Filters .FilterButton__Btn--focus {
  background-color: #f8f8f9;
  border-radius: 0;
  color: #29313e;
}
.FilterBar__Container__Filters .FilterButton__Btn__Badge {
  margin: 5px 0 5px 5px;
}
.FilterBar__Container__Filters .FilterButton__Btn__Badge--hidden[style] {
  display: none !important;
}
.FilterBar__Container__Filters .FilterButton__Btn__Icon {
  float: right;
  margin: 5px 0 5px 5px;
}
.FilterBar__Container__Filters .FilterButton__Btn__Icon__Line {
  fill: #fff;
  stroke: #797e85;
}
.FilterBar__Container__Filters .FilterButton__Btn--error {
  border-bottom: 1px solid #d21212 !important;
  border-radius: 0;
}
