@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
.AutoComplete__Label {
  color: #29313e;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
  color: #29313e;
  display: block;
  font-size: 16px;
  line-height: 30px;
  padding-bottom: 9px;
  text-align: left;
  width: 100%;
}
.AutoComplete__Input {
  color: #94989e;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  border: solid 1px #e1e2e0;
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  height: 48px;
  line-height: 24px;
  padding: 10px 15px;
  width: 100%;
}
.AutoComplete__Input--error {
  border-color: #d21212;
}
.AutoComplete__Feedback {
  color: #d21212;
  margin-top: 4px;
}
.AutoComplete__OptionContainer {
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  max-height: 50px;
  overflow-y: auto;
}
.AutoComplete__Option {
  background-color: #fff;
  color: #29313e;
  cursor: pointer;
  padding: 5px;
}
.AutoComplete__Option--active {
  background-color: #29313e;
  color: #fff;
  cursor: pointer;
  padding: 5px;
}
