@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../../assets/fonts/D-DIN-Italic.otf");
}
.FilterNeighborhood {
  position: relative;
}
.FilterNeighborhood__Container {
  background-color: #fdfdfd;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  margin-top: 55px;
  padding: 0 25px 20px 25px;
  width: 290px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  max-height: fit-content;
  padding: 16px;
  position: absolute;
  top: 0;
}
.FilterNeighborhood__ScrollContainer {
  height: 300px;
  overflow-y: auto;
}
.FilterNeighborhood__Option {
  cursor: pointer;
  padding: 8px 0;
}
.FilterNeighborhood__Option:hover {
  background-color: #bec1c6;
}
.FilterNeighborhood__Input {
  border: 0;
  border-bottom: 1px solid #29313e;
  outline: none;
  padding: 8px;
  width: 100%;
}
.FilterNeighborhood__Input:focus {
  border-bottom: 1px solid #b18935;
}
