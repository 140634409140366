@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.ReportModal {
  align-items: center;
  background-color: rgba(0,0,0,0.2);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 22;
}
.ReportModal__Loader {
  align-items: center;
  display: flex;
  justify-content: center;
}
.ReportModal--hidden {
  display: none;
}
.ReportModal__Modal {
  background-color: #fff;
  border-radius: 5px;
  height: 50%;
  padding: 15px;
  width: 50%;
}
.ReportModal__NotModal {
  height: 590px;
  padding: 0 16px;
}
@media (max-width: 768px) {
  .ReportModal__NotModal {
    height: 500px;
    padding: 0;
  }
}
.ReportModal__Evaluation {
  margin: 0 16px;
  width: 100%;
}
.ReportModal__Evaluation--nomargin {
  width: 100%;
}
@media (max-width: 768px) {
  .ReportModal__Evaluation {
    margin: 0;
    width: calc(100% - 32px);
  }
}
.ReportModal__Header {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}
.ReportModal__Header i {
  cursor: pointer;
}
.ReportModal__Toolbar {
  align-self: start;
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (max-width: 768px) {
  .ReportModal__Toolbar div,
  .ReportModal__Toolbar input {
    margin-bottom: 5px;
    margin-left: 0 !important;
  }
  .ReportModal__Toolbar button {
    width: 50%;
  }
  .ReportModal__Toolbar div {
    display: flex;
  }
}
@media (min-width: 768px) {
  .ReportModal__Toolbar {
    flex-direction: row;
  }
}
.ReportModal__Footer {
  align-items: flex-end;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 30px;
}
.ReportModal__Footer i {
  cursor: pointer;
  font-size: 42px;
}
.ReportModal__GoBackButton {
  align-items: center;
  display: flex;
  justify-content: center;
}
.ReportModal__ScrollContainer {
  max-height: 100%;
  min-height: 50%;
  overflow-x: hidden;
  overflow-y: auto;
}
.ReportModal__ScrollContainer::-webkit-scrollbar {
  background-color: #e1e2e0;
  border-radius: 10px;
  width: 8px;
}
.ReportModal__ScrollContainer::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 10px;
}
.ReportModal__Body {
  height: 100%;
  position: relative;
}
.ReportModal__Body__Header {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: bold;
  color: #b18935;
  font-size: 32px;
  left: 0;
  padding: 16px 0;
}
.ReportModal__Table {
  height: 100%;
}
.ReportModal__Actions {
  padding: 5px;
  width: 100%;
}
.ReportModal__Row {
  align-items: center;
}
.ReportModal__Col {
  padding-bottom: 5px;
  padding-top: 5px;
}
.ReportModal__Link {
  background-color: #b18935;
  background-image: none;
  border-color: #b18935;
  color: #fff;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  font-size: 16px;
  min-width: 36px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.ReportModal__Sortable {
  cursor: pointer;
}
.ReportModal__Sortable i {
  display: inline-block;
  vertical-align: sub;
}
.ReportModal__Selection {
  cursor: pointer;
}
.ReportModal__Selection i {
  cursor: pointer;
}
.ReportModal__SearchInput {
  border: 0;
  border-bottom: 1px solid #797e85;
  height: 39px;
  margin-right: 8px;
  outline: none;
  padding: 5px;
  width: 100%;
}
@media (min-width: 768px) {
  .ReportModal__SearchInput {
    width: calc(100% - 270px);
  }
}
.ReportModal__SearchInput--small {
  width: 30%;
}
.material-icons {
  display: inline-block;
  vertical-align: sub;
}
