@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.ClusterMarker {
  background-color: #29313e;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  margin-left: -10px;
  position: absolute;
  width: 36px;
}
.ClusterMarker--zoomed:hover {
  transform: scale(1.2);
}
.ClusterMarker__Text {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 8px;
  text-align: center;
}
