@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.Dwellers {
  align-items: center;
  background-color: rgba(0,0,0,0.2);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
}
.Dwellers--hidden {
  display: none;
}
.Dwellers__Modal {
  background-color: #fff;
  border-radius: 5px;
  height: 95%;
  padding: 15px;
  width: 95%;
}
.Dwellers__WppBtn {
  background-color: #009688;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
}
.Dwellers__Body {
  height: calc(95% - 185px);
}
@media (min-width: 768px) {
  .Dwellers__Body {
    height: calc(100vh - 370px);
  }
}
.Dwellers__Body i {
  font-size: 36px;
}
.Dwellers__Footer {
  align-items: center;
  display: flex;
  justify-content: center;
}
.Dwellers__Footer i {
  cursor: pointer;
}
.Dwellers__Table {
  height: calc(100% - 34px);
}
.Dwellers__FilterInput {
  border: 0;
  border-bottom: 1px solid #797e85;
  display: inline-block;
  height: 29px;
  outline: none;
  padding: 5px;
  width: 100%;
}
.Dwellers__FilterInputContainer {
  color: #29313e;
  overflow: hidden;
  overflow-x: auto;
  padding: 15px 10px 15px 0;
  width: 20%;
}
.Dwellers__ScrollContainer {
  max-height: 100%;
  min-height: 50%;
  overflow-x: hidden;
  overflow-y: auto;
}
.Dwellers__ScrollContainer::-webkit-scrollbar {
  background-color: #e1e2e0;
  border-radius: 10px;
  width: 8px;
}
.Dwellers__ScrollContainer::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 10px;
}
.Dwellers__Header {
  display: table;
  height: 39px;
  margin-top: 0;
  padding: 5px;
  width: 100%;
}
@media (min-width: 768px) {
  .Dwellers__Header {
    margin-top: 45px;
  }
}
.Dwellers__Header__Info {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}
.Dwellers__Header__Icon {
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
}
.Dwellers__Header i {
  display: inline-block;
  vertical-align: sub;
}
.Dwellers__HeaderDweller {
  align-items: center;
  display: flex;
  margin-top: 8px;
}
.Dwellers__InputsWrapper {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  position: relative;
}
@media (min-width: 768px) {
  .Dwellers__InputsWrapper {
    flex-direction: row;
  }
}
.Dwellers__InputsWrapper .AutoComplete__OptionContainer {
  box-shadow: 0.2px 0.2px 0.2px;
  left: 0;
  position: absolute;
  top: 49px;
  z-index: 1;
}
@media (min-width: 768px) {
  .Dwellers__InputsWrapper .AutoComplete__OptionContainer {
    width: calc(69% - 8px);
  }
}
.Dwellers__Sortable {
  cursor: pointer;
}
.Dwellers__SearchInput {
  display: inline-block;
  margin-right: 8px;
  width: 100%;
}
.Dwellers__SearchInput__Input {
  border: 0;
  border-bottom: 1px solid #797e85;
  display: inline-block;
  height: 39px;
  outline: none;
  padding: 5px;
  width: 100%;
}
.Dwellers__OwnerInput {
  width: 100%;
}
@media (min-width: 768px) {
  .Dwellers__OwnerInput {
    width: 97%;
  }
}
.Dwellers__OwnerInput i {
  display: inline-block;
  padding-left: 4px;
  vertical-align: middle;
}
.Dwellers__NumInput {
  border: 0;
  border-bottom: 1px solid #797e85;
  height: 39px;
  margin-right: 8px;
  outline: none;
  padding: 5px;
  width: 50%;
}
.Dwellers__NumContainer {
  display: flex;
}
.Dwellers__TabScrollContainer {
  overflow-x: visible;
  overflow-y: auto;
}
.Dwellers__TabScrollContainer::-webkit-scrollbar {
  display: none;
}
.Dwellers__TabsContainer {
  border-bottom: 1px solid #b6b6b6;
  display: flex;
  height: 34px;
  width: fit-content;
}
@media (min-width: 768px) {
  .Dwellers__TabsContainer {
    max-width: 100%;
    width: 100%;
  }
}
.Dwellers__Tab {
  color: #29313e;
  cursor: pointer;
  height: 33px;
  padding: 8px;
  white-space: nowrap;
}
.Dwellers__Tab--active {
  background-color: #fff;
  border-left: 1px solid #b6b6b6;
  border-radius: 5px 5px 0 0;
  border-right: 1px solid #b6b6b6;
  border-top: 1px solid #b6b6b6;
  color: #29313e;
  cursor: pointer;
  height: 34px;
  padding: 8px;
  white-space: nowrap;
}
.Dwellers__TabContent {
  max-height: calc(100vh - 240px);
  overflow-x: auto;
}
.Dwellers__SearchBtn {
  height: 39px;
  width: 100%;
}
.Dwellers__Btn {
  background-color: transparent;
  border: 0;
  color: #29313e;
  padding: 8px 0;
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
}
.Dwellers__Btn i {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.Dwellers__Btn--disabled i {
  cursor: not-allowed;
}
.Dwellers__TabRow {
  border-bottom: 1px solid #b6b6b6;
  color: #29313e;
  display: flex;
  padding: 8px;
}
.Dwellers__TabRow strong {
  margin-right: 3px;
}
.Dwellers__PinContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  z-index: 4;
}
.Dwellers__Pin {
  animation: bounce-without-fade 1.5s infinite;
  opacity: 0.8;
}
.Dwellers__Pin--hidden {
  display: none;
}
@-moz-keyframes bounce-without-fade {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce-without-fade {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@-o-keyframes bounce-without-fade {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes bounce-without-fade {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@-moz-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@-webkit-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@-o-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
