@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
@font-face {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN.otf");
}
@font-face {
  font-family: 'DDINDINBold';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Bold.otf");
}
@font-face {
  font-family: 'DDINDINItalic';
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/D-DIN-Italic.otf");
}
.HeaderMobile {
  background-color: #29313e;
  box-shadow: 0 2px 20px rgba(0,0,0,0.1);
  color: #fff;
  height: 60px;
  position: fixed;
  z-index: 3;
}
.HeaderMobile--home {
  background-color: #29313e;
  box-shadow: 0 2px 20px rgba(0,0,0,0.1);
  color: #fff;
  height: 60px;
  position: fixed;
  top: 0;
  z-index: 1;
}
.HeaderMobile--transparent {
  background-color: transparent;
  color: #fff;
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.HeaderMobile__Container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.HeaderMobile__LogoColumn {
  width: 60px;
}
.HeaderMobile__SearchBarColumn {
  width: calc(100vw - 120px);
}
.HeaderMobile__MenuColumn {
  width: 60px;
}
.HeaderMobile__Pane {
  align-items: center;
  background-color: rgba(0,0,0,0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}
.HeaderMobile__Modal {
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  padding: 16px;
  width: 90%;
}
.HeaderMobile__ModalHeader {
  font-family: 'DDINRegular';
  font-style: normal;
  font-weight: normal;
  color: #29313e;
}
.HeaderMobile__FormRow {
  margin-bottom: 16px;
}
